<template>
  <div class="processed">
    <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="teamInfo.length > 10 ? '没有更多了': ''"
        :immediate-check="false"
        @load="onLoad"
    >
    <div class="processed-all" v-if="userInfo && userInfo.is_team_master == 1">
      <van-dropdown-menu>
        <van-dropdown-item :title="team_value +'(' + teamTotal +')'" ref="screening">
          <div class="screening-con">
            <van-cell center :title-class="['screening-title']" :class="{'screening-title-act': screen_index == index}"
                      :title="item.name" v-for="(item, index) in TeamTags" @click="screenFun(item, index)"
                      :key="index"></van-cell>
          </div>

          <div class="btn-con">
            <div class="cancel align-center" @click="screeningCanche">取消</div>
            <div class="confirm align-center" @click="screenSubmit">确认</div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="processed-con" :class="[userInfo && userInfo.is_team_master == 1 ? 'processed-padding' : '' ]">
      <div class="processed-item vertical-center" v-for="(item, index) in teamInfo" :key="index">
        <img class="item-img" v-if="item.task && (item.task.cover_picture != '' && item.task.cover_picture != null)" :src="item.task ? item.task.cover_picture : ''" alt="">
        <img class="item-img" src="https://resources.suantan.net/mini-program/task/taskImg.png" v-else  alt="">
        <div class="item-text white-space">
          <div class="item-text-title white-space">{{item.task ? item.task.name : '酸谈社群'}}</div>
          <div class="item-text-time">{{type ==1 ? item.updated_at : item.created_at}}</div>
        </div>
        <div class="item-team white-space">{{item.team_group && item.team_group.group ? item.team_group.group.name : '未分组'}}</div>
      </div>
    </div>
    </van-list>
    <no-data v-if="teamInfo.length == 0 && list_status" :bottomBg="true"></no-data>
  </div>
</template>

<script>
import NoData from "@/components/NoData";
export default {
  name: "TeamAudit",
  props: ['type'],
  data() {
    return {
      TeamTags: [], // 小队名称列表
      team_value: '全部小队', // 选择值
      teamOption: [], // 菜单选择值
      screen_index: 0,  // 筛选的下标
      current_value: null,  // 当前选择的值
      teamInfo: [], // 当前的数据
      page: 1,
      team_group_id: '', // 当前筛选的
      teamTotal: '',
      list_status: false,
      loading: false,
      finished: false
    }
  },
  created() {
    // 判断用户身份
    if(this.userInfo && this.userInfo.is_team_master == 1) {
      this.getTeamTags()
    }else {
      this.getTeamInfo()
    }
  },
  methods: {
    // 获取小队名称列表
    getTeamTags(){
      let url = this.$api.teamTags
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.TeamTags= res.data.data
          this.TeamTags.unshift({id: -1, name: '全部小队'})
          this.team_value= '全部小队'
          this.team_group_id = -1
          this.getTeamInfo()
        }
      })
    },
    // 获取数据
    getTeamInfo() {
      let url = this.$api.groupTasks + '?page='+ this.page + '&type=' + this.type + '&team_group_id=' + this.team_group_id
      this.list_status = false
      this.$http.get(url, true).then(res =>{
        if(res.data.success){
          let _data = res.data.data.data
          this.teamTotal = res.data.data.total ? res.data.data.total : 0
          this.page++
          this.loading = false;
          if (!_data){
            this.loading= false
            this.list_status= true
            return
          }
          if(_data.length) {
            this.finished = false;
          }
          this.teamInfo = this.teamInfo.concat(_data)
          setTimeout(()=>{
            this.list_status= true
          },200)
        }
      })
    },
    // 回复默认值
    defaultFun() {
      this.page = 1
      this.teamInfo =[]
      this.loading = false;
      this.finished = true;
      this.list_status= false
    },
    // 点击筛选
    screenFun(item, index) {
      this.screen_index = index
      this.current_value = item
    },
    // 确认筛选
    screenSubmit(){
      this.$refs.screening.toggle(false)
      // 回复默认值
      this.defaultFun()
      if(this.current_value != null){
        this.team_group_id = this.current_value.id
        this.team_value = this.current_value.name
      }
      this.getTeamInfo()
    },
    // 关闭弹框
    screeningCanche() {
      this.$refs.screening.toggle(false)
    },
    // 底部加载
    onLoad(){
      if(this.list_status && this.teamInfo.length > 8){
        this.getTeamInfo()
      }
    }

  },
  components: {
    NoData
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-data/processed.scss";
</style>
