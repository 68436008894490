<template>
  <div class="not-finished">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="teamInfo.length > 10 ? '没有更多了': ''"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="not-finished-all" v-if="(userInfo && userInfo.is_team_master == 1) || diff == '2'">
        <van-dropdown-menu>
          <van-dropdown-item :title="task_value +'(' + taskTotal +')'" ref="tasking" v-if="diff == '2'">
            <div class="screening-con">
              <van-cell center :title-class="['screening-title']" :class="{'screening-title-act': task_index == index}"
                        :title="item.name" v-for="(item, index) in TaskTags" @click="taskFun(item, index)"
                        :key="index"></van-cell>
            </div>
            <div class="btn-con">
              <div class="cancel align-center" @click="taskCanche">取消</div>
              <div class="confirm align-center" @click="taskSubmit">确认</div>
            </div>
          </van-dropdown-item>
          <van-dropdown-item :title="team_value +'(' + teamTotal +')'" ref="screening" v-if="userInfo && userInfo.is_team_master == 1">
            <div class="screening-con">
              <van-cell center :title-class="['screening-title']" :class="{'screening-title-act': screen_index == index}"
                        :title="item.name" v-for="(item, index) in TeamTags" @click="screenFun(item, index)"
                        :key="index"></van-cell>
            </div>
            <div class="btn-con">
              <div class="cancel align-center" @click="screeningCanche">取消</div>
              <div class="confirm align-center" @click="screenSubmit">确认</div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>

      <div class="not-finished-con" :class="[(userInfo && userInfo.is_team_master == 1) || diff == 2 ? 'not-finished-padding' : '' ]">
        <div class="not-finished-item vertical-center" v-for="(item, index) in teamInfo" :key="index">
          <img class="item-img" :src="item.wechat && item.wechat.avatar != '' ? item.wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'" alt="">
          <div class="item-text white-space">
            {{item.wechat ? item.wechat.nickname : '酸谈社群'}}
          </div>
          <div class="item-team white-space">{{item.team_group && item.team_group.group ? item.team_group.group.name : '未分组'}}</div>
        </div>
      </div>
    </van-list>
    <no-data v-if="teamInfo.length == 0 && list_status" :bottomBg="true"></no-data>
  </div>
</template>

<script>
import NoData from "@/components/NoData";

export default {
  name: "TeamAccomplish",
  props: ['type','diff','examine','new'],
  data() {
    return {
      TaskTags: [], // 任务名称列表
      TeamTags: [], // 小队名称列表
      task_value: '限时任务', // 选择值
      team_value: '全部小队', // 选择值
      teamOption: [], // 菜单选择值
      screen_index: 0,  // 筛选的下标
      task_index: 0,  // 筛选的下标
      current_value: null,  // 当前选择的值
      current_task_value: null,  // 当前选择的值
      teamInfo: [], // 当前的数据
      page: 1,
      team_group_id: -1, // 当前筛选的
      task_group_id: '', // 当前筛选的
      teamTotal: 0,
      taskTotal: 0,
      list_status: false,
      loading: false,
      finished: false,
    }
  },
  created() {
    // 判断用户身份
    if(this.userInfo && this.userInfo.is_team_master == 1) {
      this.getTeamTags()
    }else {
      if(this.diff == "1"){
        this.getTeamInfo()
      }else if(this.diff == "2"){
        this.getTaskTags()
      }
    }
  },
  methods: {
    // 获取小队名称列表
    getTeamTags(){
      let url = this.$api.teamTags
      this.$http.get(url, true).then(res => {
        if(res.data.success) {
          this.TeamTags= res.data.data
          this.TeamTags.unshift({id: -1, name: '全部小队'})
          this.team_value= '全部小队'
          this.team_group_id = -1
          if(this.diff == "1"){
            this.getTeamInfo()
          }else if(this.diff == "2"){
            this.getTaskTags()
          }

        }
      })
    },
    // 获取任务名称列表
    getTaskTags() {
      let url = this.$api.groupLimits
      this.$http.get(url,  true).then(res => {
        if(res.data.success) {
          if(res.data.data.count == 0){
            this.loading= false
            this.list_status= true
            return
          }
          this.TaskTags = res.data.data.data
          this.task_value = res.data.data.data[0].name
          this.task_group_id = res.data.data.data[0].id
          this.taskTotal = res.data.data.count ? res.data.data.count: 0
          this.getTeamInfo()
        }
      })
    },
    // 获取数据
    getTeamInfo() {
      let url
      if (this.new){
        url = this.$api.groupFreshers + '?page='+ this.page + '&type=' + this.type + '&team_group_id=' + this.team_group_id
      }else {
        if (this.examine){
          url = this.$api.limitChecks + '?page='+ this.page + '&type=' + this.type + '&team_group_id=' + this.team_group_id + '&task_id=' + this.task_group_id
        }else {
          if(this.diff == "1"){
            url = this.$api.groupPackages + '?page='+ this.page + '&type=' + this.type + '&team_group_id=' + this.team_group_id
          }else if(this.diff == "2"){
            url = this.$api.limitTasks + '?page='+ this.page + '&type=' + this.type + '&team_group_id=' + this.team_group_id + '&task_id=' + this.task_group_id
          }
        }
      }


      this.list_status = false
      this.$http.get(url, true).then(res =>{
        if(res.data.success){
          let _data = res.data.data.data
          this.teamTotal = res.data.data.total ? res.data.data.total : 0
          this.page++
          this.loading = false;
          if(_data.length) {
            this.finished = false;
          }
          this.teamInfo = this.teamInfo.concat(_data)
          setTimeout(()=>{
            this.list_status= true
          },200)
        }
      }).catch(err =>{

        this.loading= false
        this.list_status= true

      })
    },
    // 回复默认值
    defaultFun() {
      this.page = 1
      this.teamInfo =[]
      this.loading = false;
      this.finished = true;
      this.list_status= false
    },
    // 点击筛选
    screenFun(item, index) {
      this.screen_index = index
      this.current_value = item
    },
    // 点击筛选
    taskFun(item, index) {
      this.current_task_value = item
      this.task_index = index
    },
    // 确认筛选
    screenSubmit(){
      this.$refs.screening.toggle(false)
      // 回复默认值
      this.defaultFun()
      if(this.current_value != null){
        this.team_group_id = this.current_value.id
        this.team_value = this.current_value.name
      }
      this.getTeamInfo()
    },
    // 确认筛选
    taskSubmit(){
      this.$refs.tasking.toggle(false)
      // 回复默认值
      this.defaultFun()
      if(this.current_task_value != null){
        this.task_group_id = this.current_task_value.id
        this.task_value = this.current_task_value.name
      }
      this.getTeamInfo()
    },
    // 关闭弹框
    screeningCanche() {
      this.$refs.screening.toggle(false)
    },
    // 关闭弹框
    taskCanche() {
      this.$refs.tasking.toggle(false)
    },
    // 底部加载
    onLoad(){
      if(this.list_status && this.teamInfo.length > 8){
        this.getTeamInfo()
      }
    }

  },
  components: {
    NoData
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/team-data/not-finished.scss";
</style>
